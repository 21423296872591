import { sendEmailVerification, User } from 'firebase/auth'
import { APPCONFIGS } from '../../../configs/app-configs'

import {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
} from '../../../configs/firebase'
import { mapAuthCodeToMessage } from '../../../lib/utils/map-error-to-code-handler'
import { AuthMiddlewareService } from './AuthMiddleware.service'

const googleProvider = new GoogleAuthProvider()

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    return res

    // eslint-disable-next-line
  } catch (err: any) {
    console.log(err.code)

    console.error(mapAuthCodeToMessage(err.code))
    alert(mapAuthCodeToMessage(err.code))
  }
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
  // try {
  const res = await signInWithEmailAndPassword(auth, email, password)
  return res
}

const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  // try {
  return await createUserWithEmailAndPassword(auth, email, password).then(async (res) => {
    const body = {
      name: name,
      email: email,
      uid: res.user.uid,
      profilePicture: res.user.photoURL as string,
      phoneNumber: '',
    }

    updateProfile(res.user as User, {
      displayName: name,
    })

    const response = new AuthMiddlewareService().storeUserToMiddleware(body)
    const emailVerification = sendEmailVerification(res.user, {
      url: `${APPCONFIGS.VERIFY_EMAIL_REDIRECT as string}`,
      handleCodeInApp: true,
    })
      .then((d) => {
        console.log(d)
      })
      .catch((e) => {
        console.log(e)
      })

    return Promise.all([response, emailVerification])
  })
}

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Password reset link sent!')
    // eslint-disable-next-line
  } catch (err: any) {
    console.log(err.code)

    console.error(mapAuthCodeToMessage(err.code))
    alert(mapAuthCodeToMessage(err.code))
  }
}

const logout = () => {
  return signOut(auth)
}

export {
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
}
