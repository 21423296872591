import React, { Fragment, useState } from 'react'

// stylesheet
import styles from './card.module.css'

// general imports
import { IconsRepository } from '../../../../../repository/icons/icon.repository'
import { IdName } from '../../../../../types'
import { Link } from 'react-router-dom'
import { courseCover } from '../../../../../repository/assets'

export interface ICardProps {
  id?: number
  image: string
  title: string
  tutor: string
  tutorImage: string
  studentCount: number
  length: number
  lessons: number
  languages: IdName[] | string[]
  studentImages: Array<string>
  categories: Array<IdName>
}

const DashboardCourseCard: React.FC<ICardProps> = ({
  id,
  image,
  title,
  tutor,
  tutorImage,
  lessons,
  languages,
  categories,
}) => {
  const [cover, setCover] = useState(image || courseCover)

  return (
    <div className={styles.body}>
      <Link to={`/course-preview/${id}`}>
        <img src={cover} alt={title} onError={() => setCover(courseCover)} />
      </Link>
      <h2>{title}</h2>
      <div className={styles.instructorDetails}>
        <Avatar image={tutorImage} border={false} />
        by <span>{tutor}</span>
        {/* <div className={styles.studentAvatars}>
          {studentImages.map((img, index) => {
            return <Avatar image={img} border key={index} />
          })}
        </div>
        <p className={styles.count}>{`${studentCount}+`} Students</p> */}
      </div>
      <div className={styles.courseDetails}>
        <div className={styles.detail}>
          <span>
            <IconsRepository.Category size={20} />
          </span>
          <span>
            <IconsRepository.Category size={15} />
          </span>
          {categories
            ? categories
                .map((c) => c.name)
                .reduce((p, cat) => `${p} ${cat},`, '')
                ?.slice(0, -1)
            : ''}
        </div>
        <div className={styles.detail}>
          <span>
            <IconsRepository.MenuBookIcon />
          </span>
          <span>
            <IconsRepository.MenuBookIcon size={16} />
          </span>
          {`${lessons} lessons`}
        </div>
        <div className={`${styles.detail} ${styles.lang}`}>
          <span>
            <IconsRepository.LanguageIcon />
          </span>
          {languages.map((lang, i, arr) => {
            return (
              <Fragment key={i}>
                <span>
                  {typeof lang === 'string' ? lang : lang.name}
                  {i !== arr.length - 1 && ','}
                </span>
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default DashboardCourseCard

const Avatar: React.FC<{ image: string; border: boolean }> = ({ image, border }) => {
  return <img src={image} className={`${styles.avatar} ${border && styles.border}`} />
}
