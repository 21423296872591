import React, { useEffect, useMemo, useState } from 'react'
import { ILessonPlan } from '../../../../repository/data/lesson-plan-data'
import { IdName } from '../../../../types'
import Accordion from '../../../organisms/accordion/Accordion.organism'
import ShadowedProgressBar from '../../../organisms/progress-bar-shadowed/ShadowedProgressBar.organism'

import styles from './sidebar.module.css'
import SubLessons from '../../../molecules/lesson-navigator/sub-lessons/SubLessons.molecule'
import { useParams } from 'react-router-dom'
import ReviewCard from '../../../molecules/review-card/ReviewCard.molecule'
import { useAppSelector } from '../../../../lib/hooks'
// import { UserCourse } from '../../../../types/models'

interface IAccordionProps {
  plan: Array<ILessonPlan>
  lessons: IdName[]
  courseID?: string
  lessonTracker?: number
  setLessonTracker?: (index: number) => void
  subLessonTracker?: number
  setSubLessonTracker?: React.Dispatch<React.SetStateAction<number>>
  heading?: string
}

let currentSubLessonFound = false
let nextSubLessonIsEnabled = false

const SideNav: React.FC<IAccordionProps> = ({
  lessons,
  lessonTracker,
  heading,
  subLessonTracker,
}) => {
  const [active, setActive] = useState(lessonTracker as number)
  const { courseID, lessonID, subLessonID } = useParams()
  const { user, currentSubLesson } = useAppSelector((state) => state.authentication)

  const changeActive = (index: number) => {
    setActive((active) => (active === index ? -1 : index))
  }

  const course = useMemo(() => {
    const course = user?.enrollments?.find((course) => course.course === Number(courseID))
    return course
  }, [])

  useEffect(() => {
    setActive(lessonTracker as number)
  }, [lessonTracker])

  useEffect(() => {
    currentSubLessonFound = false
    nextSubLessonIsEnabled = false
    console.log('Resetting')
  }, [lessonTracker, subLessonTracker, active, currentSubLesson, courseID, lessonID, subLessonID])

  interface IHeaderProps extends ILessonPlan {
    index?: number
  }
  const Header: React.FC<IHeaderProps> = ({ index, chapter }) => {
    return (
      <div className={styles.header}>
        {index !== undefined ? <span>{index + 1}</span> : ''}
        <h6>{chapter}</h6>
      </div>
    )
  }

  currentSubLessonFound = false
  nextSubLessonIsEnabled = false
  console.log('sub', currentSubLesson)
  return (
    <div>
      <div>
        <ShadowedProgressBar
          bgcolor='#5C187A'
          completed={course?.percentageCompleted || 0}
          heading={heading}
        />
      </div>
      <div className={styles.course__navigator}>
        <h2 className={styles.title}>Lesson navigator</h2>
        <div className={styles.body}>
          {lessons !== undefined &&
            lessons.length > 0 &&
            lessons.map((lesson, i) => {
              // console.log('Starting with ############')
              // console.log('currentSubLessonFound', currentSubLessonFound)
              // console.log('nextSubLessonIsEnabled', nextSubLessonIsEnabled)
              return (
                <Accordion
                  title={<Header chapter={`${lesson.name}`} key={lesson.id} />}
                  content={
                    <div className={styles.topics}>
                      <div className={styles.topic}>
                        {lesson.subLessons !== undefined &&
                          lesson.subLessons.map((subLesson) => {
                            const nextSub = nextSubLessonIsEnabled
                            if (subLesson.id === currentSubLesson) {
                              currentSubLessonFound = true
                              nextSubLessonIsEnabled = true
                            } else {
                              nextSubLessonIsEnabled = false
                            }
                            let isDisabled = currentSubLessonFound
                            if (!currentSubLesson) {
                              isDisabled = true
                            }
                            // let isActive = subLesson.id === currentSubLesson
                            // if (!currentSubLesson) {
                            //   isActive = subLesson.id === Number(subLessonID)
                            // }

                            return (
                              <SubLessons
                                isDisabled={isDisabled}
                                isCurrent={subLesson.id === currentSubLesson}
                                isNext={nextSub}
                                key={subLesson.id}
                                isActive={subLesson.id === Number(subLessonID)}
                                duration='13:15 Min'
                                sublesson={subLesson}
                                navigateTo={`/full-course/${courseID}/${lesson.id}/${subLesson.id}`}
                              />
                            )
                          })}
                      </div>
                    </div>
                  }
                  btnOnClick={() => changeActive(i)}
                  icon={<></>}
                  iconClose={<></>}
                  isOpen={active === i}
                  key={`${i}`}
                  extraClass={styles.customAccordion}
                />
              )
            })}
        </div>
      </div>

      <div>
        <ReviewCard courseID={courseID as string} />
      </div>
    </div>
  )
}

export default SideNav
