import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  GoogleAuthProvider,
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAmYNi93F1d8lnzN_SDtEkbn68pFqCs1gI',
  authDomain: 'camsol-training-15a63.firebaseapp.com',
  projectId: 'camsol-training-15a63',
  storageBucket: 'camsol-training-15a63.appspot.com',
  messagingSenderId: '294703267071',
  appId: '1:294703267071:web:c86b80c0f87d3fd84660ca',
  measurementId: 'G-JSE4E0M52K',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// init auth services
const auth = getAuth()

export {
  GoogleAuthProvider,
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  analytics,
  signInWithPopup,
  sendEmailVerification,
}
