import React, { useEffect } from 'react'
import DashboardFooter from '../../components/organisms/footer/DashboardFooter.organism'
import NavbarLoggedin from '../../components/organisms/navbar/NavbarLoggedin.organism'
import Curriculum from '../../components/templates/course-preview/curriculum/Curriculum.template'
import SideBar from '../../components/templates/course-preview/sidebar/Sidebar.template'
import { defaultImage } from '../../repository/assets'

import styles from './coursepreview.module.css'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { singleCourseThunk } from '../../features/course/thunks/course.thunk'
import { useNavigate, useParams } from 'react-router-dom'
import { setCurrentCourse } from '../../features/authentication/authenticationSlice'

const CoursePreview = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { singleCourse } = useAppSelector((state) => state.course)
  const { currentCourse, user } = useAppSelector((state) => state.authentication)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!user) {
      navigate(`/course-details/${id}`)
      return
    }
    if (!singleCourse || singleCourse.id !== id) {
      dispatch(singleCourseThunk(id as string))
    }
    if (!currentCourse || id !== currentCourse) {
      dispatch(setCurrentCourse(id))
    }
  }, [id])

  return (
    <>
      <NavbarLoggedin padding='32px 3.5rem' userImage={defaultImage} />
      <main className={styles.body}>
        <SideBar key={id} />
        <Curriculum />
      </main>
      <DashboardFooter />
    </>
  )
}

export default CoursePreview
