import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { singleCourseThunk, courseThunk } from '../../features/course/thunks/course.thunk'
import Loader from '../../components/atoms/loader/Loader'
import Navbar from '../../components/organisms/navbar/Navbar.organism'
import Footer from '../../components/organisms/footer/Footer.organism'
import CourseAbout from '../../components/templates/course-details/course-about/CourseAbout.template'
import CourseIntro from '../../components/templates/course-details/course-intro/CourseIntro.template'
import Peek from '../../components/templates/course-details/peek/Peek.template'
import FeaturedCourses from '../../components/templates/course-details/featured-courses/FeaturedCourses.template'
import FrequentlyAskedQuestions from '../../components/templates/course-details/frequently-asked-questions/FrequentlyAskedQuestions.template'
import DesignGridLayout from '../../components/layouts/Grid.layout'
import { IdName } from '../../types'
import NavbarLoggedin from '../../components/organisms/navbar/NavbarLoggedin.organism'
import { defaultImage } from '../../repository/assets'

const CourseDetails = () => {
  const { id } = useParams()

  const dispatch = useAppDispatch()

  const { singleCourse, courses } = useAppSelector((state) => state.course)
  const authState = useAppSelector((state) => state.authentication)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!singleCourse || singleCourse.id !== id) {
      setLoading(true)
      dispatch(singleCourseThunk(id as string)).then(() => setLoading(false))
    }
    if (!courses || courses.length === 0) {
      dispatch(courseThunk())
    }
    if (id) {
      window.scrollTo(0, 0)
    }
  }, [id])

  return (
    <>
      {loading ? (
        <Loader activity='Loading course data...' />
      ) : (
        <main>
          {authState.user && authState.user.verified ? (
            <NavbarLoggedin padding='32px' userImage={defaultImage} />
          ) : (
            <Navbar />
          )}
          <CourseIntro
            id={Number(id)}
            name={singleCourse?.name}
            language={singleCourse?.language}
            lessons={singleCourse?.lessons}
            author={singleCourse?.author}
            cover={singleCourse?.cover}
            categories={singleCourse.categories}
          />
          <Peek {...singleCourse} />
          <DesignGridLayout>
            {singleCourse.lessons ? (
              <CourseAbout lessons={singleCourse?.lessons as IdName[]} />
            ) : null}
          </DesignGridLayout>
          <br />
          <br />
          {/* <MeetInstructor {...singleCourse.author} />
          <Review /> */}
          <DesignGridLayout>
            <FeaturedCourses />
          </DesignGridLayout>
          <FrequentlyAskedQuestions />
          <Footer />
        </main>
      )}
    </>
  )
}

export default CourseDetails
