import axios from 'axios'
import { APPCONFIGS } from '../../configs/app-configs'
import { LocalStorage } from '../../services/storage/Local.storage'

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8000/api',
  baseURL: `${APPCONFIGS.BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
  },
})

export default axiosInstance
