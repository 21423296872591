/* eslint-disable */
import React, { useEffect, useState } from 'react'
import MyCourse from '../../components/templates/dashboard/main-block/my-courses/MyCourses.template'

// styles import
import styles from './mycourses.module.css'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { courseThunk } from '../../features/course/thunks/course.thunk'
import { CourseEntityType } from '../../types/models'
import SuggestedCoursesCard from '../../components/organisms/cards/suggested-course-card/SuggestedCourseCard.organism'
import { IdName } from '../../types'
import { setSuggestedCourses } from '../../features/course/slices/course.slice'

const MyCourses = () => {
  const dispatch = useAppDispatch()

  const { courses, isLoading, suggestedCourses } = useAppSelector((state) => state.course)
  const { user } = useAppSelector((state) => state.authentication)
  const [filteredCourses, setFilteredCourses] = useState<Array<CourseEntityType>>([])

  useEffect(() => {
    if (!courses || courses.length === 0) {
      dispatch(courseThunk())
    }
  }, [])

  useEffect(() => {
    if (!user) {
      return
    }
    if (courses.length > 0 && user?.enrollments?.length > 0) {
      const _courses = user.enrollments.map((c) => c.course)
      const fCourses = courses.filter((c) => _courses.includes(c.id!))
      setFilteredCourses(fCourses)
      if (suggestedCourses.length === 0) {
        const suggested = getSuggestedCourses(fCourses, courses)
        dispatch(setSuggestedCourses(suggested))
      }
    }
  }, [courses])

  const getSuggestedCourses = (
    userCourses: Array<CourseEntityType>,
    allCourses: Array<CourseEntityType>,
  ) => {
    const suggestions = allCourses.map((course) => {
      const _course = { ...course, rating: 0 }

      for (const ucourse of userCourses) {
        if (_course.id === ucourse.id) {
          break
        }

        if (_course.author?.id === ucourse.author?.id) {
          _course.rating++
        }

        _course.categories?.forEach((cat) => {
          if (ucourse.categories?.some((c) => c.id === cat.id)) {
            _course.rating++
          }
        })
      }

      return _course
    })

    const sortedSuggestions = suggestions.sort((a, b) => b.rating - a.rating)
    const suggestedCourseIds = sortedSuggestions.slice(0, 3).map((c) => c.id)

    return allCourses.filter((course) => suggestedCourseIds.includes(course.id))
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.my__courses}>
          <h1 className={styles.heading1}>My Courses</h1>
          <div className={`${styles.suggestions} ${styles.my__courses__container}`}>
            {isLoading
              ? Array(4)
                  .fill(0)
                  .map((_v, index) => <div key={index} className={styles.loading__state}></div>)
              : null}
            {!isLoading &&
              filteredCourses.map((course: CourseEntityType, index: number) => {
                return (
                  <MyCourse
                    key={index}
                    title={course.name as string}
                    author={course.author}
                    cover={course.cover}
                    lessons={course.lessons}
                    courseID={course?.id}
                  />
                )
              })}
          </div>
        </div>
        <div className={styles.suggested__courses}>
          <h2 className={styles.heading2}>Suggested Courses</h2>
          <div className={styles.suggestions}>
            {isLoading
              ? Array(4)
                  .fill(0)
                  .map((_v, index) => <div key={index} className={styles.loading__state}></div>)
              : null}
            {!isLoading &&
              suggestedCourses.length > 0 &&
              suggestedCourses.map((course: CourseEntityType, index: number) => {
                return (
                  <SuggestedCoursesCard
                    category={course.categories as any[]}
                    courseID={course.id}
                    key={index}
                    img={course.cover?.url as string}
                    title={course.name as string}
                    name={course.author?.name as string}
                    time='6h:30mins'
                    lessonNumber={5}
                    languages={course.language as IdName[]}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default MyCourses
