import axiosInstance from '../../../lib/hooks/axios'
import { CourseEntity } from '../../../types/models'
import { LocalStorage } from '../../storage/Local.storage'

export type ReviewType = {
  message: string
  rating: number
  user: {
    email: string
  }
}

interface Ent extends CourseEntity {
  author: Array<{ name: string }>
}

export class CourseService {
  public async getAllCourses() {
    const response = await axiosInstance.get('/courses')
    let courses = response.data?.items || []
    courses = courses.map((course: Ent) => ({ ...course, author: course.author?.at(0) }))
    return courses
  }

  public async getSingleCourse(id: string) {
    const response = await axiosInstance.get(`/courses/${id}`)
    const course = response?.data
    if (course) {
      return { ...course, author: course.author?.at(0) }
    }
    return course
  }

  public async enrollCourse({ id, email }: { id: string; email: string }) {
    const response = await axiosInstance.post(
      `/courses/${id}/enroll`,
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
        },
      },
    )

    return response?.data?.user
  }

  public async reviewCourse(courseId: string, data: ReviewType) {
    const response = await axiosInstance.post(`/courses/${courseId}/review`, data, {
      headers: {
        Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
      },
    })
    return response?.data
  }

  public async courseProgress({
    courseID,
    lessonID,
    sublessonID,
    email,
  }: {
    courseID: string
    lessonID: string
    sublessonID: string
    email: string
  }) {
    const response = await axiosInstance.post(
      `/courses/${courseID}/progress/lessons/${lessonID}/sub-lessons/${sublessonID}`,
      { email, status: 'completed' },
      {
        headers: {
          Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
        },
      },
    )
    return response?.data
  }
}
