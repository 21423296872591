import React, { useEffect, useState } from 'react'

import Button from '../../../components/molecules/button/button.molecule'
import { LoginDataType } from '../../../types/auth.types'

import styles from './login.module.css'
import { Link } from 'react-router-dom'
// import GoogleIcon from '../../../icons/Google.icon'

import Loader from '../../../components/atoms/loader/Loader'
import ImageContainer from '../../../components/molecules/image-container/ImageContainer.module'
import { ButtonTypes } from '../../../components/atoms/enums'
import ValidateFunction, { ValidationTypes } from '../../../lib/utils/validate-function'
import CustomInputField from '../../../components/molecules/input/CustomInput.molecule'
import CheckBox from '../../../components/molecules/checkbox/Checkbox.module'
import { IconsRepository } from '../../../repository/icons/icon.repository'
import { loginImage } from '../../../repository/assets'
import { appPaths } from '../../../constants/app-paths'
import useWindowSize from '../../../lib/hooks/useWindowSize'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { APIStatus } from '../../../constants/configs'
import { loginThunkService } from '../../../features/authentication/authenticationThunks'
import Popup from '../../../components/organisms/popup/Popup.organism'
import UseError from '../../../lib/hooks/useError'

const Login = () => {
  const [passwordShow, setPasswordShow] = useState<boolean>(false)

  const { width } = useWindowSize()
  const dispatch = useAppDispatch()

  const { setErrorActive, errorActive } = UseError()
  const [formData, setFormData] = useState<LoginDataType>({
    email: '',
    password: '',
  })

  const { email, password } = formData
  const [formErrors, setFormError] = useState({} as ValidationTypes)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })

    setFormError({ ...formErrors, [e.target.name]: '' })
  }

  const handleShowPassword = () => {
    setPasswordShow(!passwordShow)
  }

  const auth = useAppSelector((state) => state.authentication)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { errors } = ValidateFunction(formData)
    setFormError(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    const data = {
      email: formData.email,
      password: formData.password,
    }

    dispatch(loginThunkService(data))
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    if (auth.errorTrace === true || auth.status === APIStatus.FULFILLED) {
      setErrorActive(true)
    }
  }, [auth.errorTrace, auth.status])

  return (
    <div className={styles.parent__div}>
      {errorActive ? (
        <Popup
          toggleActive={setErrorActive}
          active={errorActive}
          title='error'
          msg={auth.message}
        />
      ) : (
        ''
      )}
      {auth.status === APIStatus.FULFILLED ? (
        <Popup
          toggleActive={setErrorActive}
          active={auth.status === APIStatus.FULFILLED}
          title='success'
          msg={'Successfully logged in!'}
        />
      ) : (
        ''
      )}
      {auth.status === APIStatus.PENDING ? <Loader /> : ''}
      <div className={styles.input__div}>
        <div className={styles.logo}>
          <Link to='/'>
            {width < 900 ? <IconsRepository.MobileLogoIcon /> : <IconsRepository.LogoIcon />}
          </Link>
        </div>
        <form className={styles.content} onSubmit={handleSubmit}>
          <div>
            <h1 className={styles.title}>Login</h1>
            <p className={styles.p__styles}>Welcome back please enter your details</p>
            <div>
              <span className={styles.field__title}>
                Email<sup>*</sup>
              </span>
              <div className={styles.input__wrapper}>
                <CustomInputField
                  name='email'
                  value={email}
                  onChange={onChange}
                  placeholder='Enter your Email'
                />
                <p className={styles.validate__error}>{formErrors.email}</p>
              </div>
            </div>
            <div>
              <span className={styles.field__title}>
                Password<sup>*</sup>
              </span>
              <div className={styles.input__wrapper}>
                <CustomInputField
                  name='password'
                  type={passwordShow ? 'text' : 'password'}
                  value={password}
                  onChange={onChange}
                  left={true}
                  placeholder='Enter your Password'
                  rightIcon={
                    passwordShow ? (
                      <span style={{ cursor: 'pointer' }}>
                        <IconsRepository.EyeIcon
                          size={20}
                          color='#0F172A'
                          onClick={handleShowPassword}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: 'pointer' }}>
                        <IconsRepository.EyeCrossedIcon
                          size={20}
                          color='#0F172A'
                          onClick={handleShowPassword}
                        />
                      </span>
                    )
                  }
                  icon={<IconsRepository.LockIcon />}
                />
                <p className={styles.validate__error}>{formErrors.password}</p>
              </div>
            </div>
            <div className={styles.checkbox__container}>
              <div className={styles.checkbox}>
                <CheckBox />
                <p>Remember me</p>
              </div>
              <p className={styles.link}>
                <Link to={appPaths.FORGOT_PASS}>Forgot Password</Link>
              </p>
            </div>
            {/* {messag.length !== 0 ? <p className={styles.validate__error}>{message}</p> : ''} */}
            <div className={styles.submit__btn}>
              <Button
                fontSize='16px'
                type={ButtonTypes.PRIMARY}
                text={'Sign in'}
                width='100%'
                height='48px'
                className={styles.btn}
              />
            </div>
            {/* <div
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault()
              }}
              className={styles.submit__btn}
            >
              <Button
                type={ButtonTypes.SECONDARY}
                text={'Sign in with Google'}
                width='100%'
                height='48px'
                fontSize='16px'
                iconDir={IconDirection.LEFT}
                icon={<GoogleIcon />}
                className={styles.btn2}
              />
            </div> */}
          </div>
          <div className={styles.form__control}>
            <h4>
              Don&apos;t yet have an account?{' '}
              <span className={styles.link}>
                <Link to={appPaths.REGISTER}>Sign up</Link>
              </span>
            </h4>
          </div>
        </form>
      </div>
      <div className={styles.img__container}>
        <ImageContainer image={loginImage} />
      </div>
    </div>
  )
}

export default Login
