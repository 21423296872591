import React from 'react'
import { IconProps } from '../types'

const SuccessIcon = ({ size = 20, stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.0003 1.66667C5.40033 1.66667 1.66699 5.40001 1.66699 10C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 10C18.3337 5.40001 14.6003 1.66667 10.0003 1.66667ZM7.74199 13.575L4.75033 10.5833C4.42533 10.2583 4.42533 9.73334 4.75033 9.40834C5.07533 9.08334 5.60033 9.08334 5.92533 9.40834L8.33366 11.8083L14.067 6.07501C14.392 5.75001 14.917 5.75001 15.242 6.07501C15.567 6.40001 15.567 6.925 15.242 7.25001L8.91699 13.575C8.60033 13.9 8.06699 13.9 7.74199 13.575Z'
        fill='#048A81'
      />
    </svg>
  )
}

export default SuccessIcon
