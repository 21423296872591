/* eslint-disable */
import React, { ReactNode, useRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  enrollCourseThunk,
  singleCourseThunk,
} from '../../../../features/course/thunks/course.thunk'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { courseCover, defaultImage } from '../../../../repository/assets'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { ButtonTypes } from '../../../atoms/enums'
import Loader from '../../../atoms/loader/Loader'
import Button from '../../../molecules/button/button.molecule'
import ProgressBar from '../../../organisms/progressbar/ProgressBar.organism'

import styles from './sidebar.module.css'
import timeConvert from '../../../../lib/utils/time-convert'
import useWindowSize from '../../../../lib/hooks/useWindowSize'

const SideBar: React.FC = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { singleCourse, isLoading } = useAppSelector((state) => state.course)
  const { user } = useAppSelector((state) => state.authentication)
  const { width } = useWindowSize()

  const wrapperRef = useRef<HTMLElement | null>(null)
  const bodyRef = useRef<HTMLDivElement | null>(null)
  const [loading, setLoading] = useState(false)
  // @ts-ignore
  const [course, setCourse] = useState<any>({})
  const [cover, setCover] = useState(singleCourse?.cover?.url)
  const [authorCover, setAuthorCover] = useState(singleCourse?.author?.avatarUrl || defaultImage)

  useEffect(() => {
    if (singleCourse) {
      const _course = user?.enrollments?.find((enrollment) => enrollment.course === singleCourse.id)
      setCourse(_course || {})
      setCover(singleCourse?.cover?.url || courseCover)
      setAuthorCover(singleCourse?.author?.avatarUrl || defaultImage)
    }
  }, [singleCourse])

  useEffect(() => {
    if (window.innerWidth <= 786) {
      window.removeEventListener('scroll', () => {
        console.warn('Ugg')
      })
      if (bodyRef.current) bodyRef.current.style.transform = 'translateY(0px)'
      return
    }

    window.addEventListener('scroll', () => {
      if (width > 768 || window.innerWidth > 768) {
        const scrollTop = window.scrollY
        const viewportHeight = window.innerHeight
        const contentHeight = bodyRef.current?.getBoundingClientRect().height || 0

        const intersectionSpacing = 48
        const bottomPadding = 128
        const footerHeight =
          document?.querySelector('#root > div > div > footer')?.getBoundingClientRect().height ||
          250
        const scrollStop =
          document.body.getBoundingClientRect().height -
          viewportHeight -
          footerHeight -
          bottomPadding
        // if (window.innerWidth > 1300) {
        //   scrollStop = 2000
        // }

        if (
          scrollTop >= contentHeight - viewportHeight + intersectionSpacing &&
          scrollTop < scrollStop
        ) {
          const delta = scrollTop - (contentHeight - viewportHeight) - bottomPadding
          if (bodyRef.current) bodyRef.current.style.transform = `translateY(${delta}px)`
        }
        if (scrollTop < 110) {
          if (bodyRef.current) bodyRef.current.style.transform = 'translateY(0px)'
        }
      }
    })

    return () =>
      window.removeEventListener('scroll', () => {
        console.warn('Ugg')
      })
  }, [width])

  useEffect(() => {
    if (bodyRef.current && wrapperRef.current) {
      const contentHeight = bodyRef.current?.getBoundingClientRect().height || 0
      wrapperRef.current.style.minHeight = `${contentHeight + 30}px`
    }
  }, [])

  const enrollCourse = async () => {
    if (user && id) {
      setLoading(true)
      const { email } = user
      await dispatch(enrollCourseThunk({ id, email })).then(() => {
        if (
          singleCourse.lessons !== undefined &&
          singleCourse.lessons.length > 0 &&
          singleCourse.lessons[0].subLessons !== undefined &&
          singleCourse.lessons[0].subLessons.length > 0
        ) {
          navigate(
            `/full-course/${singleCourse.id}/${singleCourse.lessons[0].id}/${singleCourse.lessons[0].subLessons[0].id}`,
          )
        }
      })
      setLoading(false)
    }
  }

  const isDisabled = () => {
    if (!user) {
      return true
    }
    return (
      loading || Boolean(user.enrollments?.find((enrollment) => enrollment.course === Number(id)))
    )
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      {loading ? <Loader /> : null}
      <aside className={styles.wrapper} ref={wrapperRef}>
        <div className={styles.body} ref={bodyRef}>
          <img
            className={styles.banner}
            src={cover}
            onError={() => setCover(courseCover)}
            alt={singleCourse?.name}
          />
          <div className={styles.container}>
            <h4 className={styles.title}>{singleCourse?.name}</h4>
            <div className={styles.instructor}>
              <img
                src={authorCover}
                alt={singleCourse?.author?.name}
                onError={() => setAuthorCover(defaultImage)}
              />
              <p>
                by <span style={{ fontSize: '12px' }}>{singleCourse?.author?.name}</span>
              </p>
            </div>
          </div>
          <Divider />
          <div className={styles.container}>
            {/* <h4 className={`${styles.title} ${styles.center}`}>
            <EmojiEvents />
            <span>30%</span> Complete
          </h4> */}
            <ProgressBar
              completed={course.percentageCompleted || 0}
              bgcolor={'var(--platform-secondary-700)'}
            />
          </div>
          <Divider />
          <div className={styles.container}>
            <h4 className={styles.title}>Course Content</h4>
            <Detail
              icon={
                <span style={{ color: 'var(--platform-secondary-900)' }}>
                  <IconsRepository.MenuBookIcon size={20} />
                </span>
              }
              label={'Lessons'}
              value={`${singleCourse?.lessons?.length} lessons`}
            />
            <Detail
              icon={
                <span style={{ color: 'var(--platform-secondary-900)' }}>
                  <IconsRepository.LanguageIcon size={20} />
                </span>
              }
              label={'Language'}
              value={
                singleCourse.language
                  ?.map((lang, i, arr) => `${lang}${i < arr.length - 1 ? ',' : ''} `)
                  .join('') || ''
              }
            />
            <Detail
              icon={
                <span style={{ color: 'var(--platform-secondary-900)' }}>
                  <IconsRepository.SignalCellularAltIcon size={20} />
                </span>
              }
              // @ts-ignore
              label={'Level'}
              value={
                singleCourse.level?.reduce((p, lvl) => `${p} ${lvl},`, '')?.slice(0, -1) ||
                'Begginer, Advanced'
              }
            />
            <Detail
              icon={
                <span style={{ color: 'var(--platform-secondary-900)' }}>
                  <IconsRepository.DevicesOthersIcon size={20} />
                </span>
              }
              label={'Platform'}
              value={'Mobile, Desktop, Tablet '}
            />
          </div>
          {/* <Divider /> */}
          {/* <div className={styles.container}>
            <h4 className={styles.title}>You will Learn</h4>
            {[
              'Ideation, sketching, wireframing',
              'Prototyping Low Fidelity: Paper Prototyping',
              'High Fidelity Design',
              'User Testing',
            ].map((val) => (
              <Detail icon={<IconsRepository.DoneIcon />} value={val} key={val} />
            ))}
          </div> */}
          <div className={styles.btn}>
            <Button
              type={ButtonTypes.PRIMARY}
              fontSize='16px'
              width=' 133px'
              height='48px'
              text={'Enroll Now'}
              className={styles.btn2}
              disabled={isDisabled()}
              onClick={enrollCourse}
            />
          </div>
        </div>
      </aside>
    </>
  )
}

export default SideBar

const Divider = () => {
  return <span className={styles.divider}></span>
}

const Detail: React.FC<{ icon: ReactNode; label?: string; value: string }> = ({
  icon,
  label,
  value,
}) => {
  return (
    <div className={styles.detail}>
      {icon}
      {label && <h5>{label}:</h5>}
      <span>{value}</span>
    </div>
  )
}
