import React, { useMemo } from 'react'

import styles from './sublessons.module.css'

import SuccessIcon from '../../../../icons/Success.icon'
import PlayIcon from '../../../../icons/Play.icon'
import { IdName } from '../../../../types'
import { useNavigate } from 'react-router-dom'

type Props = {
  isActive?: boolean
  sublesson?: IdName
  duration?: string
  isDisabled?: boolean
  isCurrent?: boolean
  isNext?: boolean
  navigateTo: string
}

const SubLessons: React.FC<Props> = ({
  isActive,
  sublesson,
  duration,
  navigateTo,
  isDisabled,
  isCurrent,
  isNext,
}) => {
  const navigate = useNavigate()

  const icon = useMemo(() => {
    if (isCurrent) {
      return <SuccessIcon />
    }
    if (isActive) {
      return <PlayIcon color='white' />
    }
    if (isDisabled) {
      return (
        <span className={styles.play}>
          <PlayIcon />
        </span>
      )
    }

    return <SuccessIcon />
  }, [isActive, isCurrent, isDisabled])

  // console.group('sublesson')
  // console.log('sublesson', sublesson?.name)
  // console.log('isActive', isActive)
  // console.log('isDisabled', isDisabled)
  // console.log('isCurrent', isCurrent)
  // console.log('isNext ', isNext)

  // console.groupEnd()

  return (
    <div
      className={`${isActive === true ? styles.active : ''} ${styles.content__wrapper} ${
        isDisabled ? styles.disabled : ''
      } ${isCurrent ? styles.current : ''} ${isNext ? styles.current : ''}`}
      onClick={() => {
        if (!isDisabled) {
          navigate(`${navigateTo}?repeat=true`)
        } else if (isNext) {
          navigate(`${navigateTo}`)
        }
        if (isCurrent && isDisabled) {
          navigate(`${navigateTo}?repeat=true`)
        }
        // navigate(0)
      }}
    >
      <div>
        <span>{icon}</span>
      </div>
      <div>
        <h3 className={styles.content}>{sublesson?.name || 'No name'}</h3>
        <p className={styles.duration}>{duration}</p>
      </div>
    </div>
  )
}

export default SubLessons
