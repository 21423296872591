import UploadIcon from '../../icons/Upload.icon'
import MenuBookIcon from '../../icons/MenuBook.icon'
import SignalCellularAltIcon from '../../icons/SignalCellularAlt.icon'
import TextIncreaseIcon from '../../icons/TextIncrease.icon'
import ArrowLeftIcon from '../../icons/ArrowLeft.icon'
import LogoIcon from '../../icons/Logo.icon'
import WorkspacePremiumIcon from '../../icons/WorkspacePremium.icon'
import TrophyIcon from '../../icons/Trophy.icon'
import TimeLapseIcon from '../../icons/TimeLapse.icon'
import StarIcon from '../../icons/Star.icon'
import SortIcon from '../../icons/Sort.icon'
import ShareIcon from '../../icons/Share.icon'
import SettingsIcon from '../../icons/Settings.icon'
import SearchIcon from '../../icons/Search.icon'
import PlusIcon from '../../icons/Plus.icon'
import PlayCircleFilledIcon from '../../icons/PlayCircleFilled.icon'
import PlayCircleFilledTwo from '../../icons/PlayCircleFilledTwo'
import PatternIcon from '../../icons/Pattern.icon'
import OpenLockIcon from '../../icons/OpenLock.icon'
import OndemanVideoIcon from '../../icons/OndemanVideo.icon'
import BlueRightIcon from '../../icons/BlueRightIcon'
import BlueLeftIcon from '../../icons/BlueLeftArrow'
import IndicatorIcon from '../../icons/IndicatorIcon'
import NavMenuIcon from '../../icons/NavMenu.icon'
import MinusIcon from '../../icons/Minus.icon'
import MenuIcon from '../../icons/Menu.icon'
import CloseIcon from '../../icons/Close.icon'
import LogoutIcon from '../../icons/Logout.icon'
import DropDownIcon from '../../icons/DropDown.icon'
import BellIcon from '../../icons/Bell.icon'
import ActiveRadioButtonIcon from '../../icons/ActiveRadioButton.icon'
import DevicesOthersIcon from '../../icons/DevicesOthers.icon'
import DoneIcon from '../../icons/Done.icon'
import FilterIcon from '../../icons/Filter.icon'
import GlobeIcon from '../../icons/Globe.icon'
import GoogleIcon from '../../icons/Google.icon'
import LockIcon from '../../icons/Lock.icon'
import LogoAndDepartmentIcon from '../../icons/LogoAndDepartment.icon'
import LessonsIcon from '../../icons/Lessons.icon'
import LanguageIcon from '../../icons/Language.icon'
import VideoPlayerIcon from '../../icons/VideoPlayer.icon'
import InactiveRadioButtonIcon from '../../icons/InactiveRadioButton.icon'
import CheckboxCheckedIcon from '../../icons/CheckboxChecked.icon'
import CheckboxIcon from '../../icons/Checkbox.icon'
import MobileLogoIcon from '../../icons/MobileLogo.icon'
import ChevronRightIcon from '../../icons/ChevronRight.icon'
import ChevronLeftIcon from '../../icons/ChevronLeft.icon'
import RocketLaunchIcon from '../../icons/RocketLaunch.icon'
import CodeIcon from '../../icons/Code.icon'
import SuccessIcon from '../../icons/Success.icon'
import FileCopy from '../../icons/FileCopy'
import PauseCircleFilled from '../../icons/PauseCircleFilled'
import SmallChevronIcon from '../../icons/SmallChevron.icon'
import PlayIcon from '../../icons/Play.icon'
import QuestionAnswer from '../../icons/QuestionAnswer.icon'
import Task from '../../icons/Task.icon'
import Person from '../../icons/Person.icon'
import ChevronRight from '../../icons/ChevronRightIcon.icon'
import CheckCircleIcon from '../../icons/CheckCircle.icon'
import FailedIcon from '../../icons/Failed.icon'
import StarOutlinedIcon from '../../icons/StarOutlined.icon'
import Category from '../../icons/Category.icon'
import MaintainanceWheelIcon from '../../icons/MaintainanceWheel.icon'
import EyeIcon from '../../icons/Eye.icon'
import EyeCrossedIcon from '../../icons/EyeCrossed.icon'
import ArrowRight from '../../icons/ArrowRight.icon'

export const IconsRepository = {
  UploadIcon,
  MenuBookIcon,
  SignalCellularAltIcon,
  TextIncreaseIcon,
  ArrowLeftIcon,
  LogoIcon,
  WorkspacePremiumIcon,
  TrophyIcon,
  TimeLapseIcon,
  StarIcon,
  SortIcon,
  ShareIcon,
  SettingsIcon,
  SearchIcon,
  PlusIcon,
  PlayCircleFilledIcon,
  PlayCircleFilledTwo,
  PatternIcon,
  OpenLockIcon,
  OndemanVideoIcon,
  NavMenuIcon,
  MinusIcon,
  MenuIcon,
  CloseIcon,
  LogoutIcon,
  DropDownIcon,
  BlueLeftIcon,
  BlueRightIcon,
  IndicatorIcon,
  BellIcon,
  ActiveRadioButtonIcon,
  DevicesOthersIcon,
  DoneIcon,
  FilterIcon,
  GlobeIcon,
  GoogleIcon,
  LockIcon,
  LogoAndDepartmentIcon,
  LessonsIcon,
  LanguageIcon,
  InactiveRadioButtonIcon,
  VideoPlayerIcon,
  CheckboxCheckedIcon,
  CheckboxIcon,
  MobileLogoIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  RocketLaunchIcon,
  CodeIcon,
  SuccessIcon,
  SmallChevronIcon,
  PlayIcon,
  FileCopy,
  PauseCircleFilled,
  QuestionAnswer,
  Task,
  Person,
  CheckCircleIcon,
  FailedIcon,
  ChevronRight,
  StarOutlinedIcon,
  Category,
  MaintainanceWheelIcon,
  EyeIcon,
  EyeCrossedIcon,
  ArrowRight,
}
