export const appPaths = {
  HOME_PAGE: '/',
  COURSES_PAGE: '/all-courses',
  FULL_COURSE_PAGE: '/full-course/:courseID/:lessonID/:subLessonID',
  COURSE_PREVIEW_PAGE: '/course-preview/:id',
  COURSE_CATEGORY: '/course-category',
  COURSE_DETAILS: '/course-details/:id',
  SEARCH_RESULTS: 'search-results',
  ICONS_SET: '/icons',
  MAINTENANCE: '/maintenance',

  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  VERIFY_EMAIL: '/auth/email-verification',
  VERIFICATION_CONFIRMATION: '/auth/verification/confirmed',
  FORGOT_PASS: '/auth/forgot-password',
  VERIFY_PIN: '/auth/verify-pin',
  CREATE_NEW_PASSWORD: '/auth/create-new-password',

  DASHBOARD: '/dashboard',
  MY_COURSES: 'my-courses',
  D_PROFILE: 'profile',
  D_NOTIFICATIONS: 'notifications',
  D_PASSWORD: 'password',
  D_ACCOUNT: 'account',
}
