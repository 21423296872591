import React from 'react'

import styles from './about.module.css'

import LessonAccordion from '../lesson-accordion/LessonAccordion.template'
import { lessonPlan } from '../../../../repository/data/lesson-plan-data'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import { IdName } from '../../../../types'
import { appPaths } from '../../../../constants/app-paths'
import { Link } from 'react-router-dom'

export type CourseLessonType = {
  lessons: IdName[]
}

const CourseAbout: React.FC<CourseLessonType> = ({ lessons }) => {
  // const videoRef = useRef<HTMLVideoElement | null>(null)
  // const [isPlaying, setPlaying] = useState(false)

  // const playPause = () => {
  //   if (isPlaying) {
  //     videoRef.current?.pause()
  //     setPlaying(false)
  //   } else {
  //     videoRef.current?.play()
  //     setPlaying(true)
  //   }
  // }

  return (
    <section className={styles.body} id='about'>
      <h1 className={styles.title}>About this course</h1>
      <div className={styles.content}>
        <aside className={styles.details}>
          <h5>Our Lesson Plan</h5>
          <div className={styles.plan}>
            <LessonAccordion plan={lessonPlan} lessons={lessons} />
          </div>
        </aside>
      </div>
      <div className={styles.button}>
        <Link to={appPaths.REGISTER}>
          <Button
            type={ButtonTypes.PRIMARY}
            text={'Enroll For Free'}
            fontSize='16px'
            className={styles.custom}
          />
        </Link>
      </div>
    </section>
  )
}

export default CourseAbout
