import React from 'react'
import { IconProps } from '../types'

const LessonsIcon = ({ size = 21, stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.9997 4.06477C17.0747 3.7731 16.058 3.6481 15.083 3.6481C13.458 3.6481 11.708 3.98144 10.4997 4.8981C9.29134 3.98144 7.54134 3.6481 5.91634 3.6481C4.29134 3.6481 2.54134 3.98144 1.33301 4.8981V17.1064C1.33301 17.3148 1.54134 17.5231 1.74967 17.5231C1.83301 17.5231 1.87467 17.4814 1.95801 17.4814C3.08301 16.9398 4.70801 16.5648 5.91634 16.5648C7.54134 16.5648 9.29134 16.8981 10.4997 17.8148C11.6247 17.1064 13.6663 16.5648 15.083 16.5648C16.458 16.5648 17.8747 16.8148 19.0413 17.4398C19.1247 17.4814 19.1663 17.4814 19.2497 17.4814C19.458 17.4814 19.6663 17.2731 19.6663 17.0648V4.8981C19.1663 4.5231 18.6247 4.2731 17.9997 4.06477ZM17.9997 15.3148C17.083 15.0231 16.083 14.8981 15.083 14.8981C13.6663 14.8981 11.6247 15.4398 10.4997 16.1481V6.56477C11.6247 5.85644 13.6663 5.31477 15.083 5.31477C16.083 5.31477 17.083 5.43977 17.9997 5.73144V15.3148Z'
        fill='#380E4A'
      />
      <path
        d='M15.083 8.6481C15.8163 8.6481 16.5247 8.7231 17.1663 8.86477V7.5981C16.508 7.4731 15.7997 7.3981 15.083 7.3981C13.6663 7.3981 12.383 7.63977 11.333 8.08977V9.4731C12.2747 8.93977 13.583 8.6481 15.083 8.6481Z'
        fill='#380E4A'
      />
      <path
        d='M11.333 10.3064V11.6898C12.2747 11.1564 13.583 10.8648 15.083 10.8648C15.8163 10.8648 16.5247 10.9398 17.1663 11.0814V9.81477C16.508 9.68977 15.7997 9.61477 15.083 9.61477C13.6663 9.61477 12.383 9.86477 11.333 10.3064Z'
        fill='#380E4A'
      />
      <path
        d='M15.083 11.8398C13.6663 11.8398 12.383 12.0814 11.333 12.5314V13.9148C12.2747 13.3814 13.583 13.0898 15.083 13.0898C15.8163 13.0898 16.5247 13.1648 17.1663 13.3064V12.0398C16.508 11.9064 15.7997 11.8398 15.083 11.8398Z'
        fill='#380E4A'
      />
    </svg>
  )
}

export default LessonsIcon
