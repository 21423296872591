import React from 'react'
import { Quiz, UserQuiz } from '../../../../types'
import radiostyles from './radio.module.css'
import checkstyles from './checkbox.module.css'
import styles from './quiz.module.css'

interface QuizProps {
  quizzes: Array<Quiz>
  userQuizzes: Array<UserQuiz>
}

const CourseUserQuiz: React.FC<QuizProps> = ({ quizzes, userQuizzes }) => {
  const getQuizStatus = ({
    quizID,
    questionID,
    answerID,
  }: {
    quizID: number
    questionID: number
    answerID: number
  }) => {
    const userQuiz = userQuizzes.find((q) => q.quiz === quizID)
    if (!userQuiz) return { status: '', remark: '' }
    const progress = userQuiz.progress
    const questionProgress = progress.find((p) => p.question === questionID)
    const answer = questionProgress?.userAnswer.find((ans) => ans.id === answerID)

    if (!answer) {
      return { status: '', remark: '' }
    }

    return { status: answer.correct ? 'success' : 'error', remark: answer.remark }
  }

  const isChecked = ({
    quizID,
    questionID,
    answerID,
  }: {
    quizID: number
    questionID: number
    answerID: number
  }) => {
    const userQuiz = userQuizzes.find((q) => q.quiz === quizID)
    if (!userQuiz) return false
    const progress = userQuiz.progress
    const questionProgress = progress.find((p) => p.question === questionID)
    const answer = questionProgress?.userAnswer.find((ans) => ans.id === answerID)

    return Boolean(answer)
  }

  return (
    <section className={styles.body}>
      {quizzes.map((quiz) => {
        return (
          <div key={`quiz_${quiz.id}`}>
            <h3 className={styles.title}>{quiz.name}</h3>
            {quiz.questions.map((question, index) => {
              if (question.type === 'single') {
                return (
                  <div key={`questions_${index}`} className={styles.questions}>
                    <h6 className={styles.question}>{`${index + 1}. ${question.text}`}</h6>
                    <ul>
                      {question.answers.map((answer) => {
                        const { status, remark } = getQuizStatus({
                          quizID: quiz.id,
                          questionID: question.id,
                          answerID: answer.id,
                        })
                        return (
                          <Radio
                            key={`answer_${answer.id}`}
                            name={question.text}
                            label={answer.value}
                            checked={isChecked({
                              quizID: quiz.id,
                              questionID: question.id,
                              answerID: answer.id,
                            })}
                            status={status}
                            remark={remark}
                          />
                        )
                      })}
                    </ul>
                  </div>
                )
              } else {
                return (
                  <div key={`questions_${index}`} className={styles.questions}>
                    <h6 className={styles.question}>{`${index + 1}. ${question.text}`}</h6>
                    <ul>
                      {question.answers.map((answer) => {
                        const { status, remark } = getQuizStatus({
                          quizID: quiz.id,
                          questionID: question.id,
                          answerID: answer.id,
                        })
                        return (
                          <CheckBox
                            key={`answer_${answer.id}`}
                            label={answer.value}
                            checked={isChecked({
                              quizID: quiz.id,
                              questionID: question.id,
                              answerID: answer.id,
                            })}
                            status={status}
                            remark={remark}
                          />
                        )
                      })}
                    </ul>
                  </div>
                )
              }
            })}
          </div>
        )
      })}
    </section>
  )
}

export default CourseUserQuiz

interface IRadioProps {
  name?: string
  label?: string
  checked: boolean
  status: string
  remark: string
}

const Radio: React.FC<IRadioProps> = ({ label, name, checked, status, remark }) => {
  return (
    <div className={radiostyles.radio_wrapper}>
      <label className={radiostyles.label}>
        <span className={`${radiostyles.circle}`} color={status}>
          <span
            className={`${radiostyles.dot} ${checked ? radiostyles.show : radiostyles.hide}`}
          ></span>
        </span>
        <input type={'radio'} name={name} />
        <span>{label}</span>
      </label>
      <span>{remark}</span>
    </div>
  )
}

interface CheckboxProps {
  label?: string
  checked: boolean
  status: string
  remark: string
}
const CheckBox: React.FC<CheckboxProps> = ({ label, checked, status, remark }) => {
  return (
    <div className={`${checkstyles.checkbox__wrapper} ${checkstyles[status]}`}>
      <label className={checkstyles.label}>
        <input type='checkbox' checked={checked} />
        <span>{label}</span>
      </label>
      <span className={checkstyles.remark}>{remark}</span>
    </div>
  )
}
