import React, { useState, Fragment, useEffect } from 'react'

// stylesheet
import styles from './coursecategory.module.css'

// general imports
// import Footer from '../../components/shared/footer/Footer'

import CourseCard from '../../components/organisms/cards/course-card/CourseCard.organism'
import Navbar from '../../components/organisms/navbar/Navbar.organism'
import Swiper from '../../components/organisms/swiper/Swiper.organism'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import NavbarLoggedin from '../../components/organisms/navbar/NavbarLoggedin.organism'
import { defaultImage } from '../../repository/assets'
import { categoriesData } from '../../repository/data/categories-data'
import { courseThunk } from '../../features/course/thunks/course.thunk'
import { CourseEntityType } from '../../types/models'
import { IdName } from '../../types'

const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

// export const categoriesData = [
//   {
//     label: 'Web development',
//     target: 'web-development',
//     desc: 'Acquire industry standard skills for free and land you dream job.',
//   },
//   {
//     label: 'Coding',
//     target: 'coding',
//     desc: 'Acquire industry standard skills for free and land you dream job.',
//   },
//   {
//     label: 'Strategic Management',
//     target: 'strategic-management',
//     desc: 'Acquire industry standard skills for free and land you dream job.',
//   },
//   {
//     label: 'UI/UX Design',
//     target: 'ui-ux-design',
//     desc: 'Acquire industry standard skills for free and land you dream job.',
//   },
//   {
//     label: 'HTML',
//     target: 'html',
//     desc: 'Acquire industry standard skills for free and land you dream job.',
//   },
// ]

const CourseCategory = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const dispatch = useAppDispatch()

  const { courses, isLoading } = useAppSelector((state) => state.course)

  const authState = useAppSelector((state) => state.authentication)

  const scrollToSection = (id: string) => {
    const element = document.querySelector('#id' + `${id}`)

    if (element !== null) {
      const y = element.getBoundingClientRect().top + window.scrollY
      window.scroll({
        top: y - 50,
        behavior: 'smooth',
      })
    }
  }

  const getCourseForCategory = (category: string): CourseEntityType[] => {
    const filters = [] as CourseEntityType[]

    for (let i = 0; i < courses.length; i++) {
      const cats: IdName[] = courses[i].categories as IdName[]
      for (let j = 0; j < cats.length; j++) {
        if (cats[j].name === category) {
          filters.push(courses[i])
        }
      }
    }

    return filters
  }

  useEffect(() => {
    if (!courses || courses.length === 0) {
      dispatch(courseThunk())
    }
  }, [])

  return (
    <>
      {authState.user && authState.user.verified ? (
        <NavbarLoggedin userImage={defaultImage} />
      ) : (
        <Navbar />
      )}
      <div className={styles.course__category}>
        <div className={styles.content}>
          <div className={styles.head}>
            <h1>Courses Categories</h1>
            <h2>Learn with industry leading experts.</h2>
            <div className={styles.categories}>
              {categoriesData.length > 0 &&
                categoriesData.map((data, index) => {
                  return (
                    <div
                      onClick={() => {
                        setActiveIndex(index)
                        scrollToSection(slugify(data))
                      }}
                      key={index}
                      className={`${styles.category} ${index === activeIndex ? styles.active : ''}`}
                    >
                      <h3>{data}</h3>
                    </div>
                  )
                })}
            </div>
          </div>

          {categoriesData.length > 0 &&
            categoriesData.map((data, index) => {
              return (
                <Fragment key={index}>
                  <div id={`id${slugify(data)}`} className={styles.all__courses}>
                    <div className={styles.content}>
                      <div className={styles.head}>
                        <h2>{data}</h2>
                        <h3>{data}</h3>
                      </div>
                    </div>

                    <div className={`${styles.courses__gallery}`}>
                      {isLoading ? (
                        <Swiper singleSlideWidth='280px' gap='32px'>
                          {Array(16)
                            .fill(0)
                            .map((_course, index) => {
                              return (
                                <Fragment key={index}>
                                  <CourseCard isLoading={true} cover={undefined} name={undefined} />
                                </Fragment>
                              )
                            })}
                        </Swiper>
                      ) : (
                        ''
                      )}
                      {!isLoading ? (
                        <Swiper singleSlideWidth='280px' gap='32px'>
                          {getCourseForCategory(data).map((item, index) => {
                            return (
                              <Fragment key={index}>
                                <CourseCard
                                  cover={item.cover}
                                  name={item.name}
                                  author={item.author}
                                  language={item.language}
                                  lessons={item.lessons}
                                  duration={440}
                                  id={item.id}
                                  level={item.level}
                                />
                              </Fragment>
                            )
                          })}
                        </Swiper>
                      ) : null}
                    </div>
                  </div>
                </Fragment>
              )
            })}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default CourseCategory
