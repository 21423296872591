import React from 'react'

import styles from './instructors.module.css'

import {
  ellipses,
  upRating,
  downhillSkiing,
  reduceCapacity,
  severeCold,
  workspacePremium,
} from '../../../../repository/assets/images'

import { IInstructor, instructors } from '../../../../repository/data'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import { Link } from 'react-router-dom'
import { appPaths } from '../../../../constants/app-paths'

const Instructors: React.FC = () => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.text}>
          <h1>Learn with other students</h1>
          <p>
            Acquire industry standard skills for free and land you dream job. Acquire industry
            standard skills for free and
          </p>
          <div>
            <Link to={appPaths.REGISTER}>
              <Button
                type={ButtonTypes.PRIMARY}
                text={'Join Our Platform'}
                className={styles.custom}
                fontSize='16px'
                height='59px'
              />
            </Link>
          </div>
        </div>
        <div className={styles.instructors}>
          <img src={ellipses} className={styles.ellipses} />
          <Dot top={5} left={42} color={'#003952'} />
          <Dot top={35} left={13} color={'#AF53D6'} />
          <Dot top={70} left={70} color={'#AF53D6'} />
          <Dot top={90} left={50} color={'#7AAAF1'} />
          <Dot top={70} left={85} color={'#36A19A'} />
          <Icon top={4} left={70} image={upRating} />
          <Icon top={30} left={22} image={downhillSkiing} />
          <Icon top={30} left={80} image={workspacePremium} />
          <Icon top={45} left={59} image={severeCold} />
          <Icon top={75} left={15} image={reduceCapacity} />
          {instructors.map((ins) => {
            return <Instructor {...ins} key={ins.name} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Instructors

const Dot: React.FC<{ top: number; left: number; color: string }> = ({ top, left, color }) => {
  const style: React.CSSProperties = {
    top: `${top}%`,
    left: `${left}%`,
    background: color,
  }

  return <span style={style} className={styles.dot}></span>
}

const Icon: React.FC<{ top: number; left: number; image: string }> = ({ top, left, image }) => {
  const style: React.CSSProperties = {
    top: `${top}%`,
    left: `${left}%`,
  }

  return <img style={style} src={image} alt='Icon' className={styles.icon} />
}

const Instructor: React.FC<IInstructor> = ({ name, image, description, position }) => {
  interface styleProps extends React.CSSProperties {
    '--w': string
  }

  const style: React.CSSProperties = {
    top: `${position.top}%`,
    left: `${position.left}%`,
  }

  const imgStyle: styleProps | undefined = position?.width
    ? { '--w': `${position?.width}rem` }
    : undefined

  return (
    <div style={style} className={styles.instructor}>
      <img src={image} alt={name} className={styles.avatar} style={imgStyle} />
      <div className={styles.content}>
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
      <span className={styles.third}></span>
      <span className={styles.second}></span>
      <span className={styles.first}></span>
    </div>
  )
}
