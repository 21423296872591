import { Outlet } from 'react-router-dom'
import React from 'react'

// stylesheet
import styles from './dash.module.css'

// general imports
import BestCourses from '../../../components/templates/dashboard/best-courses/BestCourses.template'
import MainContent from '../../../components/templates/dashboard/main-block/MainBlock.template'

const Dashboard = () => {
  return (
    <>
      <main className={styles.body}>
        <section className={styles.top}>
          <MainContent />
          {/* <SideBar /> */}
        </section>
        <section className={styles.bottom}>
          <BestCourses />
        </section>
        <div className={styles.entry__level}>
          <Outlet />
        </div>
      </main>
    </>
  )
}

export default Dashboard
