import {
  instructor1,
  instructor2,
  instructor3,
  instructor4,
  instructor5,
  instructor6,
  instructor7,
} from '../assets/images'

export interface IInstructor {
  name: string
  image: string
  description: string
  position: { top: number; left: number; width?: number }
}

export const instructors: Array<IInstructor> = [
  {
    name: 'Lizabeth',
    description: 'Civil Engineer',
    image: instructor1,
    position: { top: 2, left: 43 },
  },

  {
    name: 'Monro',
    description: 'GIS Technical Architect',
    image: instructor2,
    position: { top: 25, left: 30, width: 5 },
  },
  {
    name: 'Matopimilien',
    description: 'Marketing Assistant',
    image: instructor3,
    position: { top: 25, left: 58, width: 5 },
  },

  {
    name: 'Susi',
    description: 'Assistant Professor',
    image: instructor4,
    position: { top: 40, left: 5 },
  },
  {
    name: 'Lorine',
    description: 'Structural Engineer',
    image: instructor5,
    position: { top: 40, left: 76.5 },
  },
  {
    name: 'Raimund',
    description: 'Media Manager II',
    image: instructor6,
    position: { top: 68, left: 27 },
  },
  {
    name: 'Abdel',
    description: 'Engineer II',
    image: instructor7,
    position: { top: 80, left: 55 },
  },
]
