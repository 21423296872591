import React from 'react'
// import { Link } from 'react-router-dom'

// stylesheet
import styles from './dashboardfooter.module.css'

// general imports
import { logo } from '../../../repository/assets'
import { ButtonTypes } from '../../atoms/enums'
import Button from '../../molecules/button/button.molecule'
import CustomInputField from '../../molecules/input/CustomInput.molecule'

const DashboardFooter = () => {
  return (
    <footer className={styles.body}>
      <div style={{ width: '100%', borderTop: '1px solid black' }} />
      <div className={styles.container}>
        <div className={styles.upperHalf}>
          <div className={styles.logoDiv}>
            <img src={logo} alt='Camsol Logo' />
            <p>
              Empowering people through technology. Camsol Innovations GmbH Widenmayerstraße 34
              80538 München
            </p>
          </div>
          <div className={styles.controlDiv}>
            <span>All Courses</span>
            <span>Settings</span>
            <span>My courses</span>
            <span>profile</span>
          </div>
          <div className={styles.emailDiv}>
            <p>Subscribe to our newsletter</p>
            <div className={styles.inputDiv}>
              <CustomInputField
                placeholder={'Enter your email'}
                type={'text'}
                width={'242px'}
                color={'#a9a9a9'}
              />
              <Button
                onClick={() =>
                  window.open(
                    'https://share-eu1.hsforms.com/1kDkiNEAYT6iNcq_nhQCPhwffvmn',
                    '__blank',
                  )
                }
                text={'Send'}
                className={styles.btn}
                type={ButtonTypes.PRIMARY}
                height='48px'
                fontSize='1.6rem'
              />
            </div>
          </div>
        </div>
        <div style={{ width: '100%', borderTop: '1px solid black', margin: '24px 0px' }} />
        <div className={styles.lowerHalf}>
          <div className={styles.social}>
            <span>Connect:</span>
            <a>LinkedIn</a>
            <a>Twitter</a>
            <a>YouTube</a>
            <a>Tiktok</a>
            <a>Instagram</a>
            <a>Facebook</a>
          </div>
          <p className={styles.copyright}>© 2023 Camsol Technologies. All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default DashboardFooter
