/* eslint-disable */
import React from 'react'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import Instructor from '../../instructor/Instructor.organism'
// import Instructor from '../../instructor/Instructor'
// import StudentsEnrolled from '../../students/StudentsEnrolled'

// styles import
import styles from './suggestedcoursecard.module.css'
import { IdName } from '../../../../types'
import { Link } from 'react-router-dom'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { courseCover } from '../../../../repository/assets'

type cardsProps = {
  img: string
  title: string
  time?: string
  lessonNumber?: number
  languages: IdName[]
  name: string
  courseID?: number
  category?: any[]
}

const SuggestedCoursesCard: React.FC<cardsProps> = ({
  img,
  title,
  lessonNumber,
  languages,
  name,
  courseID,
  category,
}) => {
  const width = useWindowSize().width
  const [cover, setCover] = React.useState(img || courseCover)

  return (
    <Link to={`/course-preview/${courseID}`} className={styles.card__container}>
      <div>
        <img src={cover} alt={title} onError={() => setCover(courseCover)} />
      </div>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.participants__instructor}>
        {/* <div className={styles.participants}>
          <p className={styles.participants__instructor_p}>Participants:</p>
          <div className={styles.students__compoment}>
            <StudentsEnrolled
              imgs={[defaultImage, defaultImage, defaultImage, defaultImage, defaultImage]}
            />
          </div>
        </div> */}
        <div className={styles.instructor}>
          <p className={styles.participants__instructor_p}>Instructors:</p>
          <div
            style={{
              padding: '10px',
            }}
          >
            <Instructor img={img} name={name} />
          </div>
        </div>
      </div>
      <div className={styles.about__course}>
        <div className={styles.course__duration}>
          <span className={styles.cat}>
            <IconsRepository.Category size={width < 500 ? 15 : 20} />
          </span>
          <span>
            {category
              ? category
                  .map((c) => c.name)
                  .reduce((p, cat) => `${p} ${cat},`, '')
                  ?.slice(0, -1)
              : ''}
          </span>
        </div>
        <div className={styles.lessons}>
          <span
            style={{
              color: 'var(--platform-secondary-700)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconsRepository.LessonsIcon />
          </span>
          <span>{lessonNumber} Lessons</span>
        </div>
        <div className={styles.languages}>
          <span
            style={{
              color: 'var(--platform-secondary-700)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconsRepository.GlobeIcon />
          </span>
          <div className={styles.lang}>
            {languages.length > 0 &&
              languages.map((lang, index) => {
                return <p key={index}>{lang.name}</p>
              })}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SuggestedCoursesCard
