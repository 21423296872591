import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// stylesheet
import styles from './coursecard.module.css'

// general imports
import { CourseEntityType } from '../../../../types/models'
import { defaultImage, courseCover as courseBanner } from '../../../../repository/assets'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { useAppSelector } from '../../../../lib/hooks'

export interface LoadType {
  isLoading?: boolean
  theme?: string
  full?: string
}

const CourseCard = ({
  cover,
  theme,
  id,
  name,
  author,
  language,
  lessons,
  level,
  isLoading,
  full,
  description,
}: CourseEntityType & LoadType) => {
  const { user } = useAppSelector((state) => state.authentication)
  const [courseCover, setCover] = useState(cover?.url || courseBanner)

  return (
    <>
      <article className={`${styles.course__card} ${theme === 'light' ? styles.light : ''}`}>
        <div className={styles.image}>
          {!isLoading ? (
            <img
              src={courseCover}
              alt={name}
              onError={() => {
                setCover(defaultImage)
              }}
            />
          ) : null}
          {!isLoading ? (
            <Link to={`/course-${user ? 'preview' : 'details'}/${id}`}>
              <div className={styles.overlay}>
                <h3>
                  <div>
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11 2.66675H13V13.3334H11V2.66675ZM3 9.33341H5V13.3334H3V9.33341ZM7 6.00008H9V13.3334H7V6.00008Z'
                        fill='white'
                      />
                    </svg>
                  </div>
                  Level:{' '}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.2rem',
                      flexWrap: 'wrap',
                    }}
                  >
                    {level !== undefined &&
                      level.map((lvl, index) => {
                        return (
                          <span key={index}>
                            {lvl + `${index !== level.length - 1 ? ', ' : ''}`}
                          </span>
                        )
                      })}
                  </div>
                </h3>
                <p>{description}</p>

                <div className={styles.ref__link}>
                  <p>Enroll Now</p>
                </div>
              </div>
            </Link>
          ) : null}
        </div>
        {isLoading && full === undefined ? <div className={styles.shadow}></div> : null}
        {!isLoading ? (
          <div className={styles.content}>
            <h2>{name}</h2>
            <div className={styles.instructor}>
              <div className={styles.pic}>
                <img src={author?.avatarUrl || defaultImage} alt={author?.name} />
              </div>
              <h3>
                by <span>{author?.name}</span>
              </h3>
            </div>
            <div className={styles.more__info}>
              <div className={styles.lessons}>
                <IconsRepository.PlayCircleFilledIcon size={20} color='#5C187A' />
                <h4>{lessons?.length} lessons</h4>
              </div>
              <div className={styles.lang__globe}>
                <IconsRepository.GlobeIcon />
                <div style={{ gap: '0.8rem' }} className={styles.languages}>
                  {language}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </article>
    </>
  )
}

export default CourseCard
