import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import styles from './card.module.css'

import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { ButtonTypes, IconDirection } from '../../../atoms/enums'
import Button from '../../../molecules/button/button.molecule'
import { IdName } from '../../../../types'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { courseCover } from '../../../../repository/assets'

interface ICardProps {
  image: string
  title: string
  description: string
  length: number
  lessons: IdName[]
  id?: number
  categories: IdName[]
}

const FeaturedCard: React.FC<ICardProps> = ({
  image,
  title,
  description,
  id,
  lessons,
  categories,
}) => {
  const width = useWindowSize().width
  const [cover, setCover] = useState(image || courseCover)

  return (
    <div className={styles.body}>
      <img src={cover} alt={title} className={styles.image} onError={() => setCover(courseCover)} />
      <h6>{title}</h6>
      <p>{description}</p>
      <div className={styles.details}>
        <span className={styles.cat}>
          <IconsRepository.Category size={width < 500 ? 15 : 20} />
          {categories
            ? categories
                .map((c) => c.name)
                .reduce((p, cat) => `${p} ${cat},`, '')
                ?.slice(0, -1)
            : ''}
        </span>
        <span>
          <IconsRepository.PlayCircleFilledIcon size={20} /> {lessons.length} lessons{' '}
        </span>
      </div>
      <div>
        <Link to={`/course-details/${id}`}>
          <Button
            type={ButtonTypes.PRIMARY}
            text={'Enroll Now'}
            iconDir={IconDirection.RIGHT}
            icon={
              <span
                style={{
                  transform: 'rotate(-180deg)',
                  marginTop: '-0.5rem',
                }}
              >
                <IconsRepository.ArrowLeftIcon size={16} />
              </span>
            }
            height='48px'
            fontSize='16px'
            className={styles.custom}
          />
        </Link>
      </div>
    </div>
  )
}

export default FeaturedCard
