import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Notifications } from '@mantine/notifications'
import { MantineProvider } from '@mantine/core'

import { store } from './app/store'
import { Provider } from 'react-redux'

import App from './App'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <Notifications limit={3} />
      <App />
    </MantineProvider>
  </Provider>,
  // </React.StrictMode>,
)

reportWebVitals()
