import { useEffect, useState } from 'react'

import { useAppDispatch } from '.'
import { resetAuthenticationState } from '../../features/authentication/authenticationSlice'
import { resetVerificationState } from '../../features/authentication/verificationSlice'
import { batch } from 'react-redux'

const UseError = () => {
  const [errorActive, setErrorActive] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log('errorActive', errorActive)
    if (errorActive === true) {
      console.log('errorActive', errorActive)
      setTimeout(() => {
        setErrorActive(false)
        console.log('RESETING STATES??')
        batch(() => {
          dispatch(resetAuthenticationState())
          dispatch(resetVerificationState())
        })
      }, 5000)
    }
  }, [errorActive])

  return {
    errorActive,
    setErrorActive,
  }
}

export default UseError
