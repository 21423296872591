import React from 'react'

import styles from './progressbar.module.css'

import { IconsRepository } from '../../../repository/icons/icon.repository'
import { ProgressBarTypes } from '../../../types'

const ProgressBar: React.FC<ProgressBarTypes> = ({ bgcolor, completed }) => {
  return (
    <div className={styles.parent__container}>
      <div className={styles.trophy}>
        <span style={{ color: bgcolor }}>
          <IconsRepository.TrophyIcon />
        </span>
        <p>
          <span className={styles.percentage}>{completed}% </span>Complete
        </p>
      </div>

      <div className={styles.container}>
        <div
          className={styles.filler__styles}
          style={{ backgroundColor: bgcolor, width: `${completed}%` }}
        >
          <span className={styles.label}>{`${completed}%`}</span>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
