import React, { useMemo, useState } from 'react'
import { Quiz } from '../../../../types'
import radiostyles from './radio.module.css'
import checkstyles from './checkbox.module.css'
import styles from './quiz.module.css'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import axiosInstance from '../../../../lib/hooks/axios'
import { LocalStorage } from '../../../../services/storage/Local.storage'

interface QuizProps {
  quizzes: Array<Quiz>
  refetch: () => void
  startLoad: () => void
}

const CourseQuiz: React.FC<QuizProps> = ({ quizzes, refetch, startLoad }) => {
  type answersType = Record<number, Record<number, Set<number>>>
  const [answers, setAnswers] = useState<answersType>({})

  const isSubmitDisabled = useMemo(() => {
    const totalQuestions = quizzes.reduce((prev, curr) => {
      return prev + curr.questions.length
    }, 0)
    const totalAnswers = Object.values(answers).reduce((prev, curr) => {
      return prev + Object.keys(curr).length
    }, 0)

    return totalQuestions !== totalAnswers
  }, [answers, quizzes])

  const answerQuiz = ({
    quizID,
    questionID,
    answerID,
  }: {
    quizID: number
    questionID: number
    answerID: number
  }) => {
    const _answers = { ...answers }
    const quiz = _answers[quizID] || {}
    const questionAnswers = quiz[questionID] || new Set()
    if (questionAnswers.has(answerID)) {
      questionAnswers.delete(answerID)
    } else {
      questionAnswers.add(answerID)
    }
    quiz[questionID] = questionAnswers
    _answers[quizID] = quiz
    setAnswers(_answers)
  }

  const isChecked = ({
    quizID,
    questionID,
    answerID,
  }: {
    quizID: number
    questionID: number
    answerID: number
  }) => {
    const quiz = answers[quizID]
    if (!quiz) return false
    const questionAnswers = quiz[questionID]
    if (!questionAnswers) return false

    return questionAnswers.has(answerID)
  }

  const submitQuiz = async () => {
    startLoad()
    try {
      // eslint-disable-next-line
      const requests: any[] = []
      Object.entries(answers).forEach(([quizID, questions]) => {
        Object.entries(questions).forEach(([questionID, answerIDs]) => {
          const data = {
            quizId: Number(quizID),
            questionId: Number(questionID),
            userAnswerIds: Array.from(answerIDs),
          }
          requests.push(
            axiosInstance.post('/quiz/submit', data, {
              headers: {
                Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
              },
            }),
          )
        })
      })
      const response = await Promise.all(requests)
      console.log(response)
      refetch()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <section className={styles.body}>
      {quizzes.map((quiz) => {
        return (
          <div key={`quiz_${quiz.id}`}>
            <h3 className={styles.title}>{quiz.name}</h3>
            {quiz.questions.map((question, index) => {
              if (question.type === 'single') {
                return (
                  <div key={`questions_${index}`} className={styles.questions}>
                    <h6 className={styles.question}>{`${index + 1}. ${question.text}`}</h6>
                    <ul>
                      {question.answers.map((answer) => {
                        return (
                          <Radio
                            key={`answer_${answer.id}`}
                            name={question.text}
                            label={answer.value}
                            checked={isChecked({
                              quizID: quiz.id,
                              questionID: question.id,
                              answerID: answer.id,
                            })}
                            action={() =>
                              answerQuiz({
                                quizID: quiz.id,
                                questionID: question.id,
                                answerID: answer.id,
                              })
                            }
                          />
                        )
                      })}
                    </ul>
                  </div>
                )
              } else {
                return (
                  <div key={`questions_${index}`} className={styles.questions}>
                    <h6 className={styles.question}>{`${index + 1}. ${question.text}`}</h6>
                    <ul>
                      {question.answers.map((answer) => {
                        return (
                          <CheckBox
                            key={`answer_${answer.id}`}
                            label={answer.value}
                            checked={isChecked({
                              quizID: quiz.id,
                              questionID: question.id,
                              answerID: answer.id,
                            })}
                            action={() =>
                              answerQuiz({
                                quizID: quiz.id,
                                questionID: question.id,
                                answerID: answer.id,
                              })
                            }
                          />
                        )
                      })}
                    </ul>
                  </div>
                )
              }
            })}
          </div>
        )
      })}
      <Button
        type={ButtonTypes.PRIMARY}
        text='Submit'
        className={styles.btn}
        disabled={isSubmitDisabled}
        onClick={submitQuiz}
      />
    </section>
  )
}

export default CourseQuiz

interface IRadioProps {
  name?: string
  label?: string
  action?: () => void
  checked: boolean
}

/**
 * The component still has a lot to be done
 * for now, it was just created for a generalisation
 * but later on features and updates will be added as required
 *
 */

const Radio: React.FC<IRadioProps> = ({ label, name, action, checked }) => {
  return (
    <label
      className={radiostyles.label}
      onClick={(e) => {
        e.stopPropagation()
        if (action) {
          action()
        }
      }}
    >
      <span className={radiostyles.circle}>
        <span
          className={`${radiostyles.dot} ${checked ? radiostyles.show : radiostyles.hide}`}
        ></span>
      </span>
      <input type={'radio'} onClick={(e) => e.stopPropagation()} name={name} />
      <span>{label}</span>
    </label>
  )
}

interface CheckboxProps {
  label?: string
  action: () => void
  checked: boolean
}
const CheckBox: React.FC<CheckboxProps> = ({ label, checked, action }) => {
  return (
    <div className={checkstyles.checkbox__wrapper}>
      <label className={checkstyles.label}>
        <input
          onChange={() => action()}
          type='checkbox'
          checked={checked}
          // style={{ width: '20px', height: '20px' }}
        />
        <span>{label}</span>
      </label>
    </div>
  )
}
