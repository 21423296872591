import React, { useEffect } from 'react'

// stylesheet
import styles from './landing.module.css'

// general imports
import DefaultLayout from '../../components/layouts/Default.layout'
// import Students from '../../components/templates/landing/students/Students.template'
import Instructors from '../../components/templates/landing/meet-instructors/InstructorsSec.template'
import FeaturedCourses from '../../components/templates/landing/featured-courses/FeaturedCourses.template'
import FrequentlyAskedQuestions from '../../components/templates/course-details/frequently-asked-questions/FrequentlyAskedQuestions.template'
import Course from '../../components/templates/landing/course/Course.template'
import Carousel from '../../components/templates/landing/video-carousel/VideoCarousel.template'
import Hero from '../../components/templates/landing/hero/Hero.template'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { courseThunk } from '../../features/course/thunks/course.thunk'

const LandingPage = () => {
  const { courses } = useAppSelector((state) => state.course)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (courses !== undefined && courses.length > 0) {
      return
    } else {
      dispatch(courseThunk())
    }
  }, [])

  return (
    <div className={styles.landing}>
      <DefaultLayout padding='0'>
        <Hero />
        <Course />
        {/* <Students/> */}
        <Instructors />
        <Carousel />
        {/* <Review /> */}
        <FeaturedCourses />
        <FrequentlyAskedQuestions />
      </DefaultLayout>
    </div>
  )
}

export default LandingPage
