import styles from './footer.module.css'
import { logo } from '../../../repository/assets'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.bottom_div}>
        <div className={styles.logo_div}>
          <img src={logo} alt='Camsol Logo' />
          <div>
            <p className={styles.logo_div_text}>Empowering people through technology.</p>
            <p className={styles.logo_div_text}>Camsol Innovations GmbH</p>
            <p className={styles.logo_div_text}>Widenmayerstraße 34</p>
            <p className={styles.logo_div_text}>80538 München</p>
          </div>
        </div>
        <div className={styles.sub_container}>
          <span className={styles.main}>Camsol Products</span>
          <Link target='__blank' to='https://camsol.io/platfrom'>
            Camsol Platform
          </Link>
          <Link target='__blank' to='https://camsol.io/coin'>
            Camsol Coin
          </Link>
          <Link target='__blank' to='https://camsol.io/pay'>
            Camsol Pay
          </Link>
        </div>
        <div className={styles.sub_container}>
          <span className={styles.main}>Quick Links</span>
          <Link to='/all-courses'>All Courses</Link>
          <Link to='/categories'>Course Categories</Link>
        </div>
        <div className={styles.sub_container_div}>
          {/* <div className={styles.sub_container}>
            <span className={styles.main}>For Startups</span>
            <Link target='__blank' to='https://camsol.io/company-building'>
              Company Building
            </Link>
            <Link target='__blank' to='https://camsol.io/company-building#design-sprint'>
              Design Sprint
            </Link>
            <Link target='__blank' to='https://camsol.io/company-building#prototyp'>
              Prototyp entwickln
            </Link>
          </div> */}
          {/* <div className={styles.sub_container}>
            <span className={styles.main}>Platform</span>
            <Link target='__blank' to='https://app.camsol.io/'>
              Camsol Platform
            </Link>
            <Link target='__blank' to='https://training.camsol.io/'>
              Camsol Training
            </Link>
            <Link
              target='__blank'
              to='https://camsol.notion.site/c54964cae5f24be3be492e75836d2286?v=6ea06f7168c144558e4acc632464e669&pvs=4'
            >
              Jobs
            </Link>
          </div> */}
        </div>
        <div className={styles.newsletter_div}>
          <p className={styles.main}>Newsletter</p>
          <p className={styles.text}>
            The world of technology is constantly evolving and we want to keep you up to date on the
            latest trends and innovations from Camsol.
          </p>
          <div className={styles.inputDiv}>
            <input type='text' placeholder='Enter your email' className={styles.input} />
            <button
              className={styles.button}
              onClick={() =>
                window.open('https://share-eu1.hsforms.com/1kDkiNEAYT6iNcq_nhQCPhwffvmn', '__blank')
              }
            >
              Send
            </button>
          </div>
        </div>
      </div>
      <p className={styles.copyright}>© 2024 Camsol Technologies. All rights reserved</p>
    </footer>
  )
}

export default Footer
