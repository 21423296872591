/* eslint-disable */
import React, { Fragment } from 'react'
import styles from './videocarousel.module.css'

import Swiper from '../../../organisms/swiper/Swiper.organism'
import CarouselCard from '../../../organisms/cards/carousel-card/CarouselCard.organism'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { useAppSelector } from '../../../../lib/hooks'
import { IdName } from '../../../../types'
import CourseCard from '../../../organisms/cards/course-card/CourseCard.organism'
import { Link } from 'react-router-dom'

const Carousel: React.FC = () => {
  const { width } = useWindowSize()

  const { courses, isLoading } = useAppSelector((state) => state.course)

  return (
    <div className={styles.rootContain}>
      <div className={styles.wrapper}>
        <div className={styles.text__content}>
          <p className={styles.welcome}> hello welcome to camsol Learning </p>
          <p className={styles.title}> Welcome to the deep end of education </p>
          <p className={styles.lorem}>
            {' '}
            Acquire industry standard skills for free and land you dream job. Camsol training is
            dedicated to helping engaged talents acquire top notch skills and excel in the
            professional world.{' '}
          </p>
        </div>
      </div>

      <div className={styles.slider}>
        <div className={styles.sliderTrack}>
          {isLoading ? (
            <Swiper
              singleSlideWidth={`${width < 500 ? 298 : 575}px`}
              gap={width < 500 ? '1.6rem' : '3.2rem'}
            >
              {Array(16)
                .fill(0)
                .map((_course, index) => {
                  return (
                    <Fragment key={index}>
                      <CourseCard full='yes' isLoading={true} cover={undefined} name={undefined} />
                    </Fragment>
                  )
                })}
            </Swiper>
          ) : (
            ''
          )}
          <Swiper
            singleSlideWidth={`${width < 500 ? 298 : 575}px`}
            gap={width < 500 ? '1.6rem' : '3.2rem'}
            p={true}
          >
            {courses.map((course) => {
              return (
                <Link to={`/course-details/${course.id}`} key={course.id}>
                  <CarouselCard
                    categories={course.categories!}
                    title={course.name as string}
                    descr={course.description as string}
                    time='4h: 13min'
                    lessons={course.lessons as IdName[]}
                    image={course.cover?.url || ''}
                  />
                </Link>
              )
            })}
          </Swiper>
        </div>

        <div
          style={{
            marginTop: '0.2rem',
            animationDelay: '3s',
          }}
          className={styles.sliderTrack}
        >
          {isLoading ? (
            <Swiper
              singleSlideWidth={`${width < 500 ? 298 : 575}px`}
              gap={width < 500 ? '1.6rem' : '3.2rem'}
            >
              {Array(16)
                .fill(0)
                .map((_course, index) => {
                  return (
                    <Fragment key={index}>
                      <CourseCard full='yes' isLoading={true} cover={undefined} name={undefined} />
                    </Fragment>
                  )
                })}
            </Swiper>
          ) : (
            ''
          )}
          <Swiper
            singleSlideWidth={`${width < 500 ? 298 : 575}px`}
            gap={width < 500 ? '1.6rem' : '3.2rem'}
          >
            {courses.map((course) => {
              return (
                <Link to={`/course-details/${course.id}`} key={course.id}>
                  <CarouselCard
                    categories={course.categories!}
                    title={course.name as string}
                    descr={course.description as string}
                    time='4h: 13min'
                    lessons={course.lessons as IdName[]}
                    image={course.cover?.url as string}
                  />
                </Link>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Carousel
