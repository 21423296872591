/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

// styles import
import styles from './mycourses.module.css'

// general imports
import Accordion from '../../../../organisms/accordion/Accordion.organism'
import Details from '../../../../organisms/details/Details.organism'
import Instructor from '../../../../organisms/instructor/Instructor.organism'
import ProgressBar from '../../../../organisms/progressbar/ProgressBar.organism'
import VideoModule from '../../../../organisms/videomodule/VideoModule.organism'

import { IconsRepository } from '../../../../../repository/icons/icon.repository'
import Button from '../../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../../atoms/enums'
import {
  CourseEntityType,
  InstructorEntityType,
  UserCourse,
  UserEnrollment,
} from '../../../../../types/models'
import { Cover, IdName } from '../../../../../types'
import { useAppSelector } from '../../../../../lib/hooks'
import {
  getCurrentLessonFromCourse,
  getCurrentSublessonFromCourse,
} from '../../../../../features/authentication/authenticationSlice'
import courseSlice from '../../../../../features/course/slices/course.slice'
import { CourseService } from '../../../../../services/api/course/Course.service'
// import axiosInstance from '../../../../../lib/hooks/axios'

type MyCourseType = {
  title?: string
  cover?: Cover
  description?: string
  author?: InstructorEntityType
  lessons?: IdName[]
  courseID?: number
}

const MyCourse: React.FC<MyCourseType> = ({
  title,
  description,
  author,
  cover,
  lessons,
  courseID,
}) => {
  const [currentModuleExpanded, setCurrentModuleExpanded] = useState(-1)
  const { user } = useAppSelector((state) => state.authentication)
  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState<CourseEntityType | undefined>()

  const btnOnClick = (moduleIndex: number) => {
    setCurrentModuleExpanded(moduleIndex === currentModuleExpanded ? -1 : moduleIndex)
  }

  const [enrollment, currentLessonID, currentSubLesson] = useMemo(() => {
    const enrollment: UserEnrollment | undefined = user?.enrollments?.find(
      (enrollment) => enrollment.course === Number(courseID),
    )

    const sublesson = getCurrentSublessonFromCourse(user, courseID!)
    const lesson = getCurrentLessonFromCourse(user, courseID!)

    return [enrollment, lesson, sublesson]
  }, [courseID])

  useEffect(() => {
    const getCourse = async () => {
      setLoading(true)
      const courseService = new CourseService()
      const response = await courseService.getSingleCourse(String(courseID))
      console.log('title', title)
      console.log('course', response)
      setCourse(response)
      setLoading(false)
    }

    getCourse()
  }, [courseID])

  // eslint-disable-next-line
  function getSubLessonStatus(subLessons: any[], currentSubLessonId: string): any[] {
    let nextSubLessonIsEnabled = false
    let isCurrentSubLessonFound = false

    const newSubLessons = []

    if (subLessons) {
      for (let subLesson of subLessons) {
        if (!currentSubLessonId) {
          newSubLessons.push({ isCurrent: false, isDisabled: true, ...subLesson })
          continue
        }
        let isDisabled = !nextSubLessonIsEnabled
        let isCurrent = subLesson.id === currentSubLessonId

        if (isCurrent) {
          isCurrentSubLessonFound = true
          nextSubLessonIsEnabled = true
          isDisabled = false
        } else {
          nextSubLessonIsEnabled = false
        }

        if (!isCurrentSubLessonFound) {
          isCurrent = true
          isDisabled = false
        }

        newSubLessons.push({ isCurrent, isDisabled, ...subLesson })
      }
    }

    return newSubLessons
  }

  const currentLesson = course?.lessons?.find((lesson) => lesson.id === currentLessonID)
  const firstLessonsubs = course?.lessons?.at(0)?.subLessons || []
  const subLessons = getSubLessonStatus(
    currentLesson?.subLessons || firstLessonsubs,
    currentSubLesson || '',
  )
  // console.log('subLessons', subLessons)

  return (
    <div className={styles.container}>
      <Accordion
        title={
          <div className={styles.head}>
            <Details
              img={cover?.url as string}
              title={title as string}
              coursename={description !== undefined ? (description as string).substring(0, 40) : ''}
            />
            <div className={styles.instructor__container}>
              <Instructor
                img={author?.avatarUrl as string}
                name={author?.name !== undefined ? (author?.name as string).split(' ')[0] : ''}
                role='instructor'
              />
            </div>
            {/* <div className={styles.students__container}>
              <StudentsEnrolled imgs={[defaultImage, defaultImage, defaultImage]} students={60} />
            </div> */}
            <div className={styles.progressbar}>
              <ProgressBar
                bgcolor='var(--platform-secondary-700)'
                completed={enrollment?.percentageCompleted || 0}
              />
            </div>
          </div>
        }
        icon={
          <span>
            <IconsRepository.DropDownIcon />
          </span>
        }
        iconClose={
          <span className={`${styles.arrow} ${currentModuleExpanded === 0 ? styles.active : ''}`}>
            <IconsRepository.DropDownIcon />
          </span>
        }
        isOpen={currentModuleExpanded === 0}
        btnOnClick={() => btnOnClick(0)}
        extraClass={currentModuleExpanded === 0 ? styles.customAccordion : ''}
        content={
          loading ? (
            <div className={styles.load}>Loading...</div>
          ) : (
            <>
              <div className={styles.content}>
                <div style={{ borderTop: '1px solid #5C187A', marginTop: '2.4rem' }}>
                  <h2 className={styles.accordion__heading2}>{currentLesson?.name}</h2>
                  {subLessons ? (
                    <>
                      {subLessons.map((singleDta, index) => {
                        return (
                          <VideoModule
                            key={index}
                            link={`/full-course/${courseID}/${currentLessonID}/${singleDta.id}`}
                            text={singleDta.name as string}
                            index={1}
                            isCurrent={singleDta.isCurrent}
                            isDisabled={singleDta.isDisabled}
                          />
                        )
                      })}
                    </>
                  ) : null}
                </div>
                {/* )
                })} */}
                <div className={styles.resume__learning}>
                  <div></div>
                  <div className={styles.btns}>
                    {/* <span>View Syllabus</span> */}
                    <Link to={`/course-preview/${courseID}`}>
                      <Button
                        text='View Syllabus'
                        type={ButtonTypes.PRIMARY}
                        className={`${styles.custom} ${styles.syllabus}`}
                      />
                    </Link>
                    {currentLessonID && currentSubLesson ? (
                      <Link
                        to={`/full-course/${courseID}/${currentLessonID}/${currentSubLesson}?repeat=true`}
                      >
                        <Button
                          type={ButtonTypes.PRIMARY}
                          text={'Resume Learning'}
                          className={styles.custom}
                        />
                      </Link>
                    ) : (
                      <Button
                        type={ButtonTypes.PRIMARY}
                        text={'Resume Learning'}
                        disabled
                        className={styles.custom}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )
        }
      />
    </div>
  )
}

export default MyCourse
